/* eslint-disable no-console */
<template>
  <v-app>
    <v-scale-transition group leave-absolute mode="in-out">
      <v-app-bar
        app
        key="app_bar"
        clipped-left
        color="primary"
        elevation="0"
        v-if="appFrame"
      >
        <v-btn
          class="mr-4"
          @click="hideMobileNavigation = !hideMobileNavigation"
          v-if="isMobile"
          icon
        >
          <v-icon style="color: white">
            {{ hideMobileNavigation ? "fas fa-times" : "fa fa-bars" }}
          </v-icon>
        </v-btn>
        <span
          class="headline font-weight-bold titleBasa ml-0 mr-2 white--text"
          @click="goToHome()"
        >
          <LogoBasa v-if="!isCompras" />
          <img
            src="@/assets/logoCompras-01.svg"
            v-else
            alt=""
            width="180px"
            srcset=""
          />
        </span>
        <span
          class="
            headline
            font-weight-light
            titleBasa
            ml-0
            mr-5
            white--text
            d-none d-md-block
          "
          style="position: relative; top: 8px"
        >
          <span
            style="
              position: absolute;
              top: -18px;
              left: 0;
              font-size: 12.5px;
              font-weight: normal;
              color: rgba(255, 255, 255, 0.8);
              width: 100%;
              text-align: left;
            "
          >
            La
          </span>
          manera Fácil
        </span>

        <v-spacer />

        <v-text-field
          v-if="$store.state.visibleSearch"
          v-model="search"
          solo-inverted
          dense
          light
          filled
          style="border-radius: 15px !important"
          flat
          hide-details
          :label="`Buscar ${$store.state.searchTerm}`"
          prepend-inner-icon="fa-search"
          class="d-none d-md-flex color-search"
        />

        <v-spacer />

        <div
          class="mr-3"
          v-if="this.$store.state.user.type === 'sudo' && !isMobile"
          @click="hideSideBar"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab x-small v-bind="attrs" v-on="on">
                <i :class="hideMenuIcon"></i>
              </v-btn>
            </template>
            <span>{{ hideMenuToolTip }}</span>
          </v-tooltip>
        </div>

        <span
          class="
            headline
            font-weight-bold
            titleBasa
            ml-0
            mr-5
            white--text
            d-none d-md-block
          "
          style="position: relative; top: -6px"
        >
          Plataforma
          <span
            style="
              position: absolute;
              bottom: -18px;
              left: 0;
              font-size: 14px;
              font-weight: normal;
              color: rgba(0, 0, 0, 0.4);
              width: 100%;
              text-align: right;
            "
          >
            Administrativa
          </span>
        </span>
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        app
        permanent
        class="navigations"
        dark
        :color="$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.6)' : '#091228'"
        key="app_nav"
        clipped
        v-if="navBar && hideMobileNavigation"
      >
        <v-list
          nav
          dense
          style="
            background: transparent !important;
            border-radius: 15px !important;
          "
        >
          <v-list-item
            two-line
            class="pa-0 my-1"
            style="border-radius: 15px !important"
          >
            <v-list-item-avatar class="my-1 ml-2">
              <img :src="avatarUser" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                class="subtitle-1 white--text font-weight-bold"
              >
                {{ user.name ? user.name : "Usuario" }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      absolute
                      right
                      top
                      icon
                      v-on="on"
                      @click="$router.push({ path: '/profile' })"
                    >
                      <v-icon> fa-solid fa-user-gear </v-icon>
                    </v-btn>
                  </template>
                  <span>Configuraciones de usuario</span>
                </v-tooltip>

                <!-- <i
                style="cursor: pointer; color: gray"
                class="fas fa-edit ml-3"
              /> -->
              </v-list-item-title>

              <v-list-item-subtitle class="primary--text">
                {{ user.type ? roleLabels[user.type].name : "cargo" }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mb-3"></v-divider>

          <v-list-item
            class="v-list-item--link ml-2"
            v-if="$store.state.user.type == 'sudo'"
            to="//tracking.ocho.life"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon large>fa-external-link</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Tracking</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div v-for="item in items" :key="item.title">
            <v-list-item
              v-if="!item.children"
              :key="item.title"
              class="pa-0 v-list-item--link"
              style="
                padding-left: 12px !important;
                border-radius: 15px !important;
              "
              link
              :disabled="item.disabled"
              :to="item.link"
              v-on:click="handleFunction(item)"
            >
              <v-list-item-icon>
                <v-icon
                  large
                  :class="[
                    {
                      'new-help-request':
                        item.title === 'Peticiones de Ayuda' &&
                        helpRequests.length != 0,
                    },
                  ]"
                >
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    :class="[
                      {
                        'new-help-request':
                          item.title === 'Peticiones de Ayuda' &&
                          helpRequests.length != 0,
                      },
                    ]"
                  >
                    {{ item.title }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- show selected business on sidebar -->
            <v-list-group
              class="business-active ml-0"
              v-if="selectedBusiness && item.printBusiness"
            >
              <template v-slot:activator>
                <v-list-item-avatar
                  id="businessMenu"
                  v-if="selectedBusiness.logo"
                >
                  <v-img
                    :src="selectedBusiness.logo.original || ochoLogoSrc"
                    max-height="80%"
                    max-width="80%"
                  >
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="selectedBusiness.shortName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                class="v-list-item--link"
                style="margin-left: 0px; border-radius: 12px !important"
              >
                <v-btn
                  @click="qrDialog = true"
                  class="mt-0"
                  block
                  elevation="0"
                  color="primary"
                >
                  <span style="font-size: 20px"><b>Generar QR</b> </span>
                </v-btn>
              </v-list-item>

              <v-list-item
                v-for="subItem in submenuBusinessItems"
                :key="subItem.index"
                :to="`${subItem.link}`"
                class="v-list-item--link"
                style="
                  margin-left: 20px;
                  padding-left: 20px;
                  border-radius: 15px !important;
                "
              >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.title">
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- customers and prices list -->
              <!-- <v-list-item
                v-show="selectedBusiness.isWarehouse"
                v-for="subItem in WarehouseBusinesses"
                :key="subItem.index"
                :to="`${subItem.link}`"
                style="padding-left: 70px"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.title">
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
            </v-list-group>

            <v-list-group
              class="business-active v-list-item--link"
              v-if="selectedCorporative && item.printCorporatives"
            >
              <template v-slot:activator>
                <v-list-item-avatar id="cargoMenu">
                  <v-img
                    v-if="selectedCorporative.logo"
                    :src="selectedCorporative.logo.original"
                    :lazy-src="selectedCorporative.logo.loading"
                    max-height="80%"
                    max-width="80%"
                  />
                  <v-img
                    v-else
                    :src="ochoLogoSrc"
                    max-height="80%"
                    max-width="80%"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="selectedCorporative.shortName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="subItem in corporativeMenu"
                :key="subItem.index"
                :to="`${subItem.link}`"
                style="padding-left: 50px; border-radius: 15px !important"
                class="v-list-item--link"
              >
                <v-list-item-content class="v-list-item--link">
                  <v-list-item-title v-text="subItem.title">
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- customers and prices list -->
              <v-list-item
                v-show="selectedCorporative.isWarehouse"
                v-for="subItem in WarehouseBusinesses"
                :key="subItem.index"
                :to="`${subItem.link}`"
                class="v-list-item--link"
                style="padding-left: 70px; border-radius: 15px !important"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="subItem.title"
                    class="v-list-item--link"
                  >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group
              v-if="item.children"
              :key="item.title"
              v-model="item.active"
              value="true"
              link
              :disabled="item.disabled"
            >
              <template v-slot:activator>
                <v-list-item-avatar v-if="item.logo">
                  <v-img
                    :src="item.logo || ochoLogoSrc"
                    max-height="80%"
                    max-width="80%"
                  >
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-icon v-if="item.icon">
                  <v-icon large>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.title"> </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(subItem, i) in Array.isArray(item.children)
                  ? item.children
                  : submenuBusinessItems"
                :key="i"
                :v-show="!subItem.sudo"
                :to="
                  Array.isArray(item.children)
                    ? subItem.link
                    : `${subItem.link}/${item.index}`
                "
                :style="
                  Array.isArray(item.children)
                    ? 'padding-left: 50px; border-radius: 15px !important;'
                    : 'padding-left: 70px; border-radius: 15px !important;'
                "
              >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.title">
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>

        <template v-slot:append>
          <div
            class="pa-2 pt-1"
            style="background-color: rgba(255, 255, 255, 0)"
          >
            <v-btn
              @click="logOut"
              color="primary"
              style="padding: 14px !important"
              block
              large
              :elevation="0"
            >
              <v-icon>fa-sign-out-alt</v-icon>
              <span v-if="!mini" class="ml-2 white--text font-weight-bold">
                Cerrar Sesión
              </span>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </v-scale-transition>

    <v-main>
      <background key="app_bg" v-if="!appFrame" :mobile="isMobile" />
      <transition-page>
        <router-view v-if="!loading" />
        <v-container v-else class="fill-height">
          <v-row align-content="center" justify="center" class="pa-0 ma-0">
            <v-col cols="12" xs="12" sm="6" md="4" lg="4" class="text-center">
              <!-- <lottie
                :options="defaultOptions"
                v-on:animCreated="handleAnimation"
              /> -->
              <v-progress-circular
                :width="3"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
      </transition-page>
    </v-main>
    <v-footer
      app
      padless
      height="40px"
      fixed
      color="#060e1e"
      style="border-top: 1px solid #85858536"
      dark
      v-if="appFrame"
    >
      <v-col class="text-left caption" cols="2">
        <span>
          <i class="fas fa-code-branch"></i>
          v{{ version }}
        </span>
      </v-col>

      <v-col class="text-center caption" cols="8" v-if="!isMobile">
        de
        <i class="fas fa-mug-hot"></i> a <i class="fas fa-code"></i> por:
        <b>
          <a class="white--text" href="http://ocho.life" target="_blank"
            >OCHO APP.</a
          >
        </b>
        <span>&copy; Ocho Corporation S.A. de C.V.</span>
      </v-col>
      <!-- <v-col cols="2">
        <v-switch
          v-model="$vuetify.theme.dark"
          hide-details
          dense
          hidden
          
        ></v-switch>
      </v-col> -->
    </v-footer>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-snackbar top :timeout="0" v-model="refreshRequired">
      La versión del panel administrativo se ha actualizado. Refresca la página
      para disfrutar de nuevas características
      <v-btn color="primary" text @click="refreshPage"> Refrescar </v-btn>
    </v-snackbar>

    <v-dialog persistent v-model="qrDialog" v-if="qrDialog" max-width="70%">
      <generate-qr @cancel="qrDialog = false" :code="selectedBusiness.qrCode" />
    </v-dialog>
    <!-- <card-payments
      v-if="$store.state.user.type == 'sudo' && modalCardPayments"
      @success="modalCardPayments = false"
      @cancel="modalCardPayments = false"
    />
    <reversals
      v-if="$store.state.user.type == 'sudo' && modalReversals"
      @success="modalReversals = false"
      @cancel="modalReversals = false"
    /> -->

    <!-- <orders-report
      v-if="$store.state.user.type == 'sudo' && ordersReportDialog"
      @success="ordersReportDialog = false"
      @cancel="ordersReportDialog = false"
    /> -->
  </v-app>
</template>

<script>
import LogoBasa from "./components/LogoBasa";
import TransitionPage from "./components/TransitionPage";
import Background from "./components/Background";
import Lottie from "./components/Lottie";
import * as animationData from "./assets/ochoColor.json";
import mobile from "is-mobile";
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import generateQr from "@/components/generateQR";
// import cardPayments from "./views/sudo/card-payments/card-payments";
// import reversals from "./views/sudo/reversals/reversals";
// import ordersReport from "./views/sudo/orders-reports/new-report";

var businessObserver = null;

import { menuRoleLabels } from "./menu/index";

export default {
  name: "App",
  components: {
    LogoBasa,
    TransitionPage,
    Background,
    generateQr,
    lottie: Lottie,
    // cardPayments,
    // reversals,
    // ordersReport,
  },

  data: () => ({
    qrDialog: false,
    qrSrc: null,
    modalUploadQr: false,
    version: process.env.VUE_APP_VERSION,
    anim: null,

    imageQRFile: null,
    animationSpeed: 1.1,
    defaultOptions: {
      animationData: animationData.default,
      loop: true,
      autoplay: true,
    },
    modalCardPayments: false,
    modalReversals: false,
    ordersReportDialog: false,
    user: {
      name: null,
      type: null,
    },
    drawer: null,
    mini: false,
    hideMobileNavigation: true,
    versionListener: null,
    loading: true,
    snackbar: false,
    snackbarText: null,
    refreshRequired: false,
    roleLabels: {},
    submenuBusinessItems: [
      {
        index: 0,
        title: "General",
        link: `/business/general`,
        disabled: false,
      },
      // {
      //   index: 1,
      //   title: "Cuentas bancarias",
      //   link: `/business/bankAccounts`,
      //   allowed: ["sudo", "businessOwner"],
      //   disabled: false,
      // },
      // { index: 2, title: "Mapa", link: `/business/map`, disabled: false },
      {
        index: 3,
        title: "Horarios",
        link: `/business/schedule`,
        disabled: false,
      },
      {
        index: 4,
        title: "Categorías",
        link: `/business/sections`,
        disabled: false,
      },
      {
        index: 5,
        title: "Complementos de productos",
        link: `/business/addons`,
        disabled: false,
      },
      {
        index: 6,
        title: "Productos",
        link: `/business/products`,
        disabled: false,
      },
      {
        index: 7,
        title: "Descuentos",
        link: `/business/discounts`,
        disabled: false,
      },
      {
        index: 8,
        title: "Mapa",
        link: `/business/map`,
        disabled: false,
      },
      {
        index: 9,
        title: "Mesas",
        link: `/business/tables`,
        disabled: false,
      },
      {
        index: 10,
        title: "Habitaciones",
        link: `/business/rooms`,
        disabled: false,
      },
      {
        index: 11,
        title: "Usuarios Vip",
        link: `/business/UserListVip`,
        disabled: false,
      },
    ],
    // WarehouseBusinesses: [
    //   {
    //     index: 9,
    //     title: "Lista de precios",
    //     link: `/business/priceLists`,
    //     disabled: false,
    //   },
    //   {
    //     index: 10,
    //     title: "Clientes preferenciales",
    //     link: `/business/customers`,
    //     disabled: false,
    //   },
    // ],
    // corporativeMenu: [
    //   {
    //     index: 11,
    //     title: "General",
    //     link: `/cargo/general`,
    //     disabled: false,
    //   },
    //   { index: 12, title: "Mapa", link: `/cargo/map`, disabled: false },
    //   {
    //     index: 13,
    //     title: "Puntos permitidos",
    //     link: `/cargo/points`,
    //     disabled: false,
    //   },
    //   {
    //     index: 14,
    //     title: "Usuario Maestro",
    //     link: `/cargo/user`,
    //     disabled: false,
    //   },
    //   {
    //     index: 15,
    //     title: "Contratos",
    //     link: `/cargo/contracts`,
    //     disabled: false,
    //   },
    // ],
    ochoLogoSrc:
      "https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df",
    hideMenuIcon: "fas fa-eye-slash",
    hideMenuToolTip: "Esconder menu",
    helpRequests: [],
    baseRoute: {
      sudo: "/orders",
      businessOwner: "/orders",
    },
  }),
  computed: {
    ...mapState([
      "sudoBusinessesList",
      "selectedBusiness",
      "selectedCorporative",
      "isCompras",
    ]),
    avatarUser() {
      return this.isCompras
        ? require("@/assets/avatar-compras.png")
        : require("@/assets/avatar.png");
    },
    items() {
      let menu = [];
      if (this.user.type) {
        menu = [...this.roleLabels[this.user.type].menu];
      } else {
        menu = [];
      }
      return menu;
    },
    appFrame() {
      return this.$store.state.appFrame;
    },
    navBar() {
      return this.$store.state.navBar;
    },
    isMobile() {
      return mobile();
    },
    search: {
      get: function () {
        return this.$store.state.search;
      },
      set: function (newValue) {
        this.$store.commit("setSearch", newValue);
      },
    },
  },
  mounted() {
    var getDomain = document.domain;
    var search = getDomain.search("compra");

    if (search != -1) {
      this.addIsCompras(true);
      const favicon = document.getElementById("favicon");
      const title = document.getElementById("title");
      title.innerHTML = "Panel Compras hn";
      favicon.href = require("@/assets/avatar-compras.png");
      this.$vuetify.theme.themes.dark.primary = "#FFC634";
      this.$vuetify.theme.themes.light.primary = "#FFC634";
    } else {
      this.addIsCompras(false);
    }

    this.$store.commit("setSearch", "");
    this.$vuetify.theme.dark = this.$store.state.darkmode;
    this.roleLabels = menuRoleLabels;

    fb.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        console.debug(authUser);
        this.$binding("user", db.collection(`appUsers`).doc(authUser.uid)).then(
          (user) => {
            this.loading = false;

            if (
              !this.roleLabels.hasOwnProperty(user.type) ||
              this.$store.state.user.active == false
            ) {
              this.$store.commit("setUserValidity", false);
              this.snackbarText =
                "Este perfíl de usuario no tiene permitido ingresar a la plataforma";
              this.snackbar = true;
              this.logOut();
            } else {
              if (this.$router.currentRoute.name == "login") {
                this.$router.push({ path: this.baseRoute[this.user.type] });
              } else if (
                this.$router.currentRoute.meta.allowedRoles &&
                !this.$router.currentRoute.meta.allowedRoles.includes(user.type)
              ) {
                //validating user permissions for this route
                this.$router.push({ path: this.baseRoute[this.user.type] });
              }

              if (
                this.selectedBusiness &&
                this.$router.currentRoute.path.includes("/business/")
              ) {
                document.getElementById("businessMenu").click();
              } else {
                this.addBusiness(null);
              }
            }
          }
        );
      } else {
        this.loading = false;
        if (this.$router.history.current.fullPath != "/login")
          this.$router.push({ path: "/login" });
      }
    });

    if (this.isMobile) {
      this.hideMobileNavigation = false;
    }
  },
  watch: {
    user: function (val) {
      if (val.type) {
        if (!this.roleLabels.hasOwnProperty(val.type) || val.active == false) {
          this.$store.commit("setUserValidity", false);
          this.snackbarText =
            "Este perfíl de usuario no tiene permitido ingresar a la plataforma";
          this.snackbar = true;
          this.logOut();
        } else {
          this.$store.commit("setUser", val);
        }
      }
    },
  },

  methods: {
    ...mapActions(["addBusiness", "addCorporative", "addIsCompras"]),
    handleFunction(item) {
      if (item.flag) {
        this[item.flag] = true;
      } else if (item.function) {
        item.function();
      }
      if (this.isMobile) this.hideMobileNavigation = false;
    },

    refreshPage() {
      window.location = window.location.href + "?eraseCache=true";
    },

    async businessListener(val) {
      if (businessObserver) {
        await businessObserver();
      }

      businessObserver = db
        .collection("businesses")
        .doc(val[".key"])
        .onSnapshot((response) => {
          let business = response.data();
          business[".key"] = response.id;
          this.addBusiness(business);
        });
    },
    logOut() {
      if (this.user) {
        fb.auth()
          .signOut()
          .then(() => {
            this.$store.state.selectedBusiness = "";
          });
      }
    },
    setQrImage(e) {
      this.imageQRFile = e.target.files[0];

      if (this.imageQRFile.type.indexOf("image/") === -1) {
        alert("Por favor seleccionar una imagen");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.qrSrc = event.target.result;
          this.modalUploadQr = true;
        };

        reader.readAsDataURL(this.imageQRFile);
      } else {
        alert("Lo sentimos, formato no soportado");
      }
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    toggleDarkmode() {
      let currentMode = this.$vuetify.theme.dark;
      this.$store.commit("setDarkMode", !currentMode);
      this.$vuetify.theme.dark = !currentMode;
    },

    goToHome() {
      this.$router.push({ path: "/" });
    },

    hideSideBar() {
      const navBar = document.querySelector(".v-navigation-drawer");
      const main = document.querySelector("main");

      if (navBar.style.width == "256px") {
        navBar.style.width = "0px";
        main.style.padding = "64px 0px 45px 0px";
        this.hideMenuIcon = "fas fa-eye";
        this.hideMenuToolTip = "Mostrar menu";
      } else {
        navBar.style.width = "256px";
        main.style.padding = "64px 0px 45px 256px";
        this.hideMenuIcon = "fas fa-eye-slash";
        this.hideMenuToolTip = "Esconder menu";
      }
    },
    getHelpRequest() {
      if (this.user.type) {
        this.$binding(
          "helpRequests",
          db.collection("helpRequests").where("status", "!=", "completed")
        );
      }
    },
  },
  async beforeDestroy() {
    if (this.versionListener) {
      await this.versionListener();
    }
  },
};
</script>

<style src="./assets/css/animate.css"></style>

<style lang="scss">
@import "@/main.scss";
@import "~@/assets/scss/brands.scss";
@import "~@/assets/scss/fontawesome.scss";
@import "~@/assets/scss/light.scss";
@import "~@/assets/scss/regular.scss";
@import "~@/assets/scss/solid.scss";

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_light-webfont.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
.color-search {
  background-color: #ffffff !important;
}
body .v-application {
  font-family: "nexa" !important;
  // position: fixed;
  // top:0;
}

body .v-application {
  .display-1,
  .display-2,
  .display-3,
  .display-4,
  .headline,
  .title,
  .subtitle-1,
  .subtitle-2,
  .body-1,
  .body-2,
  .caption,
  .overline {
    font-family: "nexa" !important;
  }
}

.v-card__title {
  word-break: normal !important;
}

.cardBox {
  background-color: white;
  border-radius: 15px;
  padding: 8px 4px !important;
  flex-grow: 0 !important;
  width: 100%;
  // min-width: calc(100vw - 24px);
}

.v-dialog {
  border-radius: 15px !important;
}
.v-card {
  border-radius: 15px !important;
  white-space: pre-wrap;
}

.v-snack__wrapper {
  border-radius: 15px !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.v-btn--rounded {
  // border-radius: 10px !important;
  font-weight: bold !important;
  text-transform: unset !important;
}

.disclaimer-text {
  font-size: 14px;
  font-weight: 500;

  span {
    display: inline-block;
    line-height: 13px;
    font-weight: normal;
    font-size: 12px;
  }
}

body
  .v-application
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  border-radius: 15px !important;
  padding: 0 14px !important;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #00cdbc;
  box-shadow: none;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.v-data-table tbody tr.v-data-table__expanded__content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.v-input__append-inner {
  margin-right: 10px;
}

.v-navigation-drawer__border {
  display: none;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 6px !important;
}

.v-icon.v-icon {
  font-size: 16px !important;
}

.titleBasa svg {
  position: relative;
  height: 32px;
  top: 2px;
  // margin-right: 5px;
  display: inline-block;
}

.v-list-item__avatar {
  // -ms-flex-item-align: center;
  // align-self: center;
  // -webkit-box-pack: start;
  // -ms-flex-pack: start;
  // justify-content: flex-start;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.v-date-picker-table .v-btn {
  border-radius: 15px !important;
  // margin: 10px;
}

// .v-picker--date {
//   padding: 10px 0;
// }

.v-picker__actions.v-card__actions .row.no-gutters {
  padding: 0 10px;
}

// .v-picker__body{
//   // padding: 0 20px;
// }

.v-tooltip__content {
  background-color: rgba(0, 0, 0, 0.9) !important;
  border-radius: 15px !important;
}

.v-data-table-header__icon {
  margin: 0 4px !important;
  color: #00cdbc !important;
}

body
  .v-application
  .v-text-field--rounded
  > .v-input__control
  > .v-input__slot {
  border-radius: 15px !important;
}
.v-autocomplete__content.v-menu__content {
  border-radius: 15px !important;
}

.v-list-item--disabled {
  opacity: 0.3 !important;
}

.v-list-item--disabled .v-avatar.v-list-item__avatar img {
  opacity: 0.8 !important;
  filter: grayscale(100%);
}

.theme--light.v-list {
  background: #e6e6e6 !important;
}
.theme--dark.v-list {
  background: #111111 !important;
}

.v-snack__content {
  justify-content: center !important;
}

.submenu-items {
  width: 100%;
  // margin-top: 20%;
}

.logo-menu {
  border-radius: 15px;
  width: 30px;
  height: 30px;
  margin-left: -10px;
  margin-right: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #00cdbc;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #00cdbc !important;
  border-radius: 5px;
}

.v-list--rounded .v-list-item,
.v-list--rounded .v-list-item::before,
.v-list--rounded .v-list-item > .v-ripple__container {
  border-radius: 15px !important;
  display: flex;
  justify-content: center;
}
.v-list--nav .v-list-item,
.v-list--nav .v-list-item:before {
  border-radius: 12px !important;
}
.control-input {
  border: 1px solid rgba(90, 89, 89, 0.315);
  border-radius: 15px !important;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  color: black;
}

.control-input-number {
  border: 1px solid rgba(90, 89, 89, 0.315);
  border-radius: 15px !important;
  width: 100% !important;
  padding: 10px;
  margin-top: 10px;
}

.control-input:focus {
  outline: none !important;
  border: 1px solid #e1e1e1;
  box-shadow: 0 0 5px #cacaca;
}

.ant-btn-icon-only.ant-btn-sm {
  width: 40px !important;
  height: 40px !important;
  border-radius: 15px !important;
  color: rgb(255, 255, 255);
}

.ant-checkbox-checked::after {
  border: 0 !important;
}

.ant-btn-primary {
  background-color: #e4e4e4 !important;
  border-color: #e4e4e4 !important;
}

.ant-btn-primary:disabled {
  border-color: #dddddd !important;
  background: #dddddd !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e4e4e4 !important;
  border-color: #e4e4e4 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #e4e4e4 !important;
}

.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #22278f4b !important;
  border-radius: 15px;
}

.sticky {
  position: fixed !important;
  top: 65px !important;
  z-index: 2 !important;
  transition: visibility 0s, opacity 0.2s linear;
}

.v-btn .v-btn__content {
  text-transform: initial !important;
}

.screen-width {
  width: 80% !important;
}

.main-container {
  width: 100%;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .v-card__text {
    padding: 0 !important;
  }

  .container {
    padding: 0 !important;
  }

  .screen-width {
    width: 100% !important;
  }
}

textarea {
  padding-left: 20px !important;
}

.save-btn {
  border-radius: 12px !important;
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  border-radius: 10px !important;
}

.v-list-item--link {
  border-radius: 10px !important;
}

label,
h3,
p {
  color: black;
}

.leaflet-control-geosearch form input {
  padding: 17px !important;
  font-weight: bold;
}

.leaflet-control-geosearch a.reset {
  color: #e74c3c !important;
  font-weight: bold;
  font-size: 15px;
  margin-top: 5px;
}

.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 45px !important;
}

.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box
  .v-select__selections,
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed
  .v-select__selections {
  min-height: 50px !important;
}

.business-active {
  margin-left: -10px;
}
.new-help-request {
  animation: pulse 1.5s infinite;
}
.v-application .rounded-lg {
  border-radius: 15px !important;
}
.v-btn {
  border-radius: 10px !important;
}
.list-item {
  border-radius: 14px !important;
}
@keyframes pulse {
  0% {
    color: #00cdbc;
  }
  50% {
    color: #ffffff;
  }
  100% {
    color: #00cdbc;
  }
}
.bigger-font {
  font-size: 1rem;
}
.nowrap {
  white-space: nowrap !important;
}
.v-list--rounded .v-list-item,
.v-list--rounded .v-list-item::before,
.v-list--rounded .v-list-item > .v-ripple__container {
  border-radius: 15px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
// .navigations{
//   margin: 10px !important;
//   border-radius: 15px !important;
//   height: 90% !important;
// }
</style>
