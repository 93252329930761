import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: () => {
      return "/orders";
    },
  },
  {
    path: "*",
    name: "not-found",
    meta: {
      appFrame: true,
      navBar: true,
    },
    component: () => import("../views/notFound.vue"),
  },

  {
    path: "/orders",
    name: "orders",
    meta: {
      appFrame: true,
      navBar: true,
      basePath: true,
      allowedRoles: ['sudo', "businessOwner"]
    },
    component: () =>
      import(/* webpackChunkName: "orders" */ "../views/Orders.vue"),
  },
  {
    path: "/business/general",
    name: "owner-general",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "owner-general" */ "../views/business/General.vue"
      ),
  },
  {
    path: "/business/map",
    name: "owner-map",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["businessOwner", "sudo"],
    },
    component: () =>
      import(/* webpackChunkName: "owner-map" */ "../views/business/Map.vue"),
  },
  {
    path: "/business/schedule",
    name: "owner-schedule",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "owner-schedule" */ "../views/business/Schedule.vue"
      ),
  },
  {
    path: "/business/sections",
    name: "owner-sections",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "owner-sections" */ "../views/business/sections/Sections"
      ),
  },
  {
    path: "/business/products",
    name: "owner-products",
    props: true,
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "owner-products" */ "../views/business/products/products"
      ),
  },
  {
    path: "/business/addons",
    name: "owner-addons",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "owner-addons" */ "../views/business/products-addons/addons"
      ),
  },
  {
    path: "/business/discounts",
    name: "discounts",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "discounts" */ "../views/business/discounts/discounts"
      ),
  },
  {
    path: "/business/priceLists",
    name: "price-lists",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "price-lists" */ "../views/business/price-lists/price-lists"
      ),
  },
  {
    path: "/business/Zones",
    name: "Zones",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "deliveryPage" */ "../views/business/Delivery/ZonePage"
      ),
  },
  {
    path: "/business/tables",
    name: "Mesas",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "deliveryPage" */ "../views/business/table_order/table"
      ),
  },
  {
    path: "/business/rooms",
    name: "Habitaciones",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "deliveryPage" */ "../views/business/rooms_order/rooms"
      ),
  },

  {
    path: "/business/products-update",
    name: "business-products-update",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "../views/business/products-update/excel"
      ),
  },
  {
    path: "/operations/massive-operations-2",
    name: "massive-operations",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import("../views/sudo/massive-operations-2/massive-operations"),
  },
  {
    path: "/business/UserListVip",
    name: "business-products-update",
    meta: {
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "../views/business/user-lists/users"
      ),
  },
  {
    path: "/select-business",
    name: "select-business",
    meta: {
      transitionName: "fade",
      appFrame: true,
      navBar: true,
      allowedRoles: ["sudo", "businessOwner"],
    },
    component: () => import("../views/SelectBusiness"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      appFrame: true,
      titlepage: "Configuración",
      navBar: true,
      basePath: true,
      allowedRoles: ["sudo","businessOwner"],
    },
    component: () => import("../views/settings/profile.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      transitionName: "zoom",
      appFrame: false,
      navBar: false,
    },
    component: () =>
      import(/* webpackChunkName: "logs" */ "../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
