import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "ochoTempAdmin",
  storage: window.localStorage,
  modules: ["ordersHeaders", "selectedBusiness", "selectedCorporative", "selectedCity","isCompras"],
});

export default new Vuex.Store({
  state: {
    isCompras:false,
    search: "",
    searchTerm: "",
    darkmode: false,
    ordersHeaders: [],
    appFrame: false,
    navBar: false,
    user: {},
    
    userIsValid: true,
    visibleSearch: true,
    selectedBusiness: "",
    selectedCorporative: "",
    selectedCity: ""
  },
  getters: {
    userIsValid: (state) => state.userIsValid,
    userType: (state) => state.user.type,
  },
  mutations: {
    setUser(state, target) {
      state.user = target;

      if (state.user.type == "sudo" && state.user.businesses)
        state.user.businesses = [];
    },
    setBusinessesList(state, target) {
      state.businessesList = target;
    },
    setIsCompras(state, value) {
      state.isCompras = value;
    },
    setUserValidity(state, target) {
      state.userIsValid = target;
      setTimeout(() => {
        state.userIsValid = true;
      }, 3000);
    },
    setSearch(state, target) {
      state.search = target;
    },
    setOrderHeaders(state, target) {
      state.ordersHeaders = target;
    },
    setSearchTerm(state, target) {
      state.searchTerm = target;
    },
    setDarkMode(state, target) {
      state.darkmode = target;
    },
    setAppFrame(state, target) {
      state.appFrame = target;
    },
    setNavBar(state, target) {
      state.navBar = target;
    },
    setEnvironment(state, target) {
      state.environment = target;
    },
    setSelectedBusiness(state, target) {
      state.selectedBusiness = target;
    },
    setCorporative(state, target) {
      state.selectedCorporative = target;
    },

    setSelectedCity(state, target) {
      state.selectedCity = target;
    }
  },
  actions: {
    addBusiness: ({ commit, state }, business) => {
      commit("setSelectedBusiness", business);
    },
    addCorporative: ({ commit, state }, business) => {
      commit("setCorporative", business);
    },
    addIsCompras: ({ commit, state }, value) => {
      commit("setIsCompras", value);
    },
    updateCity: ({ commit, state }, cityId) => {
      commit("setSelectedCity", cityId);
    },
  },
  getters:{
    isCompras: (state) => {
      return state.isCompras;
    },
  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
