// import sudo from './businessOwner.json'
// import admin from './admin.json'
// import superSac from './superSac.json'
import businessOwner from './businessOwner.json'
// import sac from './sac.json'

export const menuRoleLabels = {
    // "sudo": sudo,
    // "admin": admin,
    // "superSac": superSac,
    // "sac": sac,
    "businessOwner": businessOwner
}