import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./firebase";
import firebase from "firebase/app";
import VueFirestore from "vue-firestore";
import "ant-design-vue/lib/transfer/style/css";
import "ant-design-vue/dist/antd.css";
import "leaflet-geosearch/assets/css/leaflet.css";
// import Antd from 'ant-design-vue';

Vue.use(VueFirestore);
Vue.use(firebase);

Object.defineProperty(Vue.prototype, "firebase", {
  get() {
    return this.$root.firebase;
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
