import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/firebase-storage";
import "firebase/functions";

let env = process.env.NODE_ENV;

const config = {
  apiKey: "AIzaSyDwqeEX-PH5IV9pMboDUSgz0g6nWAoTTho",
  authDomain: "el-ocho.firebaseapp.com",
  databaseURL: "https://el-ocho.firebaseio.com",
  projectId: "el-ocho",
  storageBucket: "el-ocho.appspot.com",
  messagingSenderId: "770366666625",
  appId: "1:770366666625:web:f6d79634f54385a09b1a80",
  measurementId: "G-2PBQGFMTGH",
};

const config_dev = {
  apiKey: "AIzaSyBE4DJkIv5Q9GLMr_N_GNTb_CmwHtune1A",
  authDomain: "el-ocho-dev.firebaseapp.com",
  projectId: "el-ocho-dev",
  storageBucket: "el-ocho-dev.appspot.com",
  messagingSenderId: "86761917155",
  appId: "1:86761917155:web:a6efdd115d08123db9d3d6",
  measurementId: "G-5KGZB79YS3",
};

firebase.initializeApp(env == "production" ? config : config_dev);

// firebase.functions().useFunctionsEmulator("http://localhost:5001");

firebase.auth().languageCode = "es";

export const db = firebase.firestore();
export const fb = firebase;
